/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800;900&display=swap'); */
html{
    margin: 0;
    padding: 0;
}
.mainanimationtext {
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom bg-gradient-radial utility if not using Tailwind's built-in radial gradients */
.bg-gradient-radial {
  background-image: radial-gradient(circle at center, var(--tw-gradient-from) 0%, var(--tw-gradient-via) 50%, var(--tw-gradient-to) 100%);
}
.bgimage{
    background-image:url('../src/backgroundimg.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
@media(max-width: 600px) {
    .bgimage{
      background-image: none;
    }
  }
  .synesans{
  font-family: Syne;
  }
  .mainanimationtext{
    font-family:Velodrama;
  }